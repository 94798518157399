<!--<template>-->
<!--&lt;!&ndash;  评论点击展开收起&ndash;&gt;-->
<!--  <div class="dp-text-ellipsis-wrapper">-->
<!--    <div class="text" :class="textClass"  :style="textStyleObject">-->
<!--      <label class="btn"  @click="showAll = !showAll"></label>-->
<!--      <span style="font-weight:450">没有一个冬天不可逾越，也没有一个春天不会到来 … … 面对抗击疫情这场人民战争，希望</span>-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->
<!--<script>-->
<!--export default {-->
<!--  name: 'community_text',-->
<!--  props: {-->
<!--    info: {-->
<!--      type: String,-->
<!--      default: '',-->
<!--    },-->
<!--    //设置超出几行隐藏-->
<!--    lineClamp: {-->
<!--      type: Number,-->
<!--      default: 3,-->
<!--    },-->
<!--    //收起是否显示-->
<!--    hiddenBtn: {-->
<!--      type: Boolean,-->
<!--      default: false,-->
<!--    },-->
<!--  },-->
<!--  data() {-->
<!--    return {-->
<!--      showAll: false,-->
<!--    }-->
<!--  },-->
<!--  computed: {-->
<!--    textStyleObject() {-->
<!--      return {-->
<!--        'max-height': this.showAll ? 'none' : `${1.5 * this.lineClamp}em`,-->
<!--      }-->
<!--    },-->
<!--    textClass() {-->
<!--      let cls = this.showAll ? 'showAll' : ''-->
<!--      cls = cls + (this.hiddenBtn ? ' hidden-btn' : '')-->
<!--      return cls-->
<!--    },-->
<!--  },-->
<!--  watch: {-->
<!--    info() {-->
<!--      this.showAll = false-->
<!--    },-->
<!--  },-->
<!--}-->
<!--</script>-->
<!--<style lang="scss">-->
<!--.dp-text-ellipsis-wrapper {-->
<!--  display: flex;-->
<!--  margin: 10px 0 10px 0;-->
<!--  overflow: hidden;-->
<!--  font-size: 16px;-->
<!--  line-height: 20px;-->

<!--  .text {-->
<!--    position: relative;-->
<!--    overflow: hidden;-->
<!--    line-height: 1.5;-->
<!--    text-align: justify;-->
<!--    text-overflow: ellipsis;-->
<!--    word-break: break-all;-->
<!--    transition: 0.3s max-height;-->
<!--  }-->
<!--  .text::before {-->
<!--    float: right;-->
<!--    height: calc(100% - 20px);-->
<!--    content: '';-->
<!--  }-->
<!--  .text::after {-->
<!--    position: absolute;-->
<!--    width: 999vw;-->
<!--    height: 999vw;-->
<!--    margin-left: -100px;-->
<!--    box-shadow: inset calc(100px - 999vw) calc(30px - 999vw) 0 0 #fff;-->
<!--    content: '';-->
<!--  }-->
<!--  .btn {-->
<!--    position: relative;-->
<!--    float: right;-->
<!--    clear: both;-->
<!--    margin-left: 10px;-->
<!--    font-size: 14px;-->
<!--    padding: 0 8px;-->
<!--    color: #AAAAAA;-->
<!--    line-height: 20px;-->
<!--    border-radius: 4px;-->
<!--    cursor: pointer;-->
<!--    z-index: 10;-->
<!--  }-->
<!--  .btn::after {-->
<!--    /* stylelint-disable-next-line */-->
<!--    font-family: element-icons !important;-->
<!--    content: '全文\e790';-->
<!--  }-->

<!--  .text.showAll {-->
<!--    max-height: none;-->
<!--  }-->
<!--  .text.showAll .btn::before {-->
<!--    visibility: hidden;-->
<!--  }-->
<!--  .text.showAll .btn::after {-->
<!--    visibility: hidden;-->
<!--  }-->
<!--  .text.showAll.hidden-btn .btn::after {-->
<!--    content: '收起\e78f';-->
<!--    visibility: visible;-->
<!--  }-->
<!--  .btn::before {-->
<!--    position: absolute;-->
<!--    left: 1px;-->
<!--    color: #333;-->
<!--    transform: translateX(-100%);-->
<!--    content: '...';-->
<!--  }-->
<!--}-->
<!--</style>-->
<template>
  <div class="wrap">
    <div :class="textOver && !btnFold ? 'inner over' : 'inner'" ref="divRef">
      <span class="content" ref="spanRef">{{ info }}</span>
    </div>
    <span class="btn" v-if="textOver" @click="btnFold = !btnFold">{{
        btnFold ? "收起" : "展开"
      }}</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      textOver: false, //文本是否超出3行
      btnFold: false, //按钮默认显示展开
    };
  },
  props: {
    info: {
      type: String,
      default: '',
    },
  },
  created() {
    this.$nextTick(() => {
      // 判断文本是否超过3行
      if (this.$refs.divRef) {
        let descHeight = window
            .getComputedStyle(this.$refs.divRef)
            .height.replace("px", "");
        this.textOver = descHeight > 60;
      }
    });
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.wrap {
  padding-bottom: 20px;
}
.over {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.btn {
  font-size: 14px;
  float: right;
  cursor: pointer;
  margin-top: 10px;
}
</style>
